$screen-ss: 480px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1400px;

@mixin media_ss {
  @media only screen and (min-width: $screen-ss) {
    @content;
  }
}

@mixin media_sm {
  @media only screen and (min-width: $screen-sm) {
    @content;
  }
}

@mixin media_md {
  @media only screen and (min-width: $screen-md) {
    @content;
  }
}

@mixin media_lg {
  @media only screen and (min-width: $screen-lg) {
    @content;
  }
}

@mixin media_xl {
  @media only screen and (min-width: $screen-xl) {
    @content;
  }
}

@mixin media_xxl {
  @media only screen and (min-width: $screen-xxl) {
    @content;
  }
}

@mixin media_custom($min-width, $max-width) {
  @media only screen and (min-width: $min-width) and (max-width: $max-width) {
    @content;
  }
}

@mixin media_custom_min_width($min-width) {
  @media only screen and (min-width: $min-width) {
    @content;
  }
}
