@import '../../../../assets/styles/medias';

.footerSingleInfoWrapper {
  border-top: 1px solid #d0d0d0;
  border-left: 0;

  &:first-child {
    border-top: 0;
  }

  @include media_lg {
    border-left: 1px solid #d0d0d0;
    border-top: 0;

    &:first-child {
      border-left: 0;
    }
  }
}

.footerSingleInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 20px;

  @include media_sm {
    padding: 30px 20px;
  }

  @include media_md {
    padding: 25px 20px;
  }

  @include media_lg {
    padding: 35px 30px;
    justify-content: flex-start;
  }
}

.footerInfoIcon {
  height: 100%;
  width: auto;
}

.footerInfoIconWrap {
  width: auto;
  margin-right: 20px;
  margin-left: 5%;

  @include media_lg {
    margin-right: 15px;
    margin-left: 0;
  }

  @include media_xl {
    margin-right: 20px;
  }
}

.footerInfoTitle {
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 11px;
  color: var(--white);
}

.footerInfoTxtArea {
  width: max-content;
}

.footerInfoTxt {
  display: inline-block;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: -3px;
  color: var(--white);

  @include media_lg {
    font-size: 19px;
  }
}

.footerInfoLink {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;

  @include media_ss {
    max-width: 260px;
  }

  @include media_lg {
    max-width: 210px;
  }

  @include media_xl {
    max-width: 260px;
  }
}

.footerInfoLink:hover {
  color: var(--color-2);
}
