@import '../../../../assets/styles/medias';

.about {
  background-color: #2ab6af;
  color: var(--white);
  padding-top: 120px;
  padding-bottom: 120px;

  @include media_xl {
    background-position: 35%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../../../assets/img/preload-about-section-banner.png);
    background-color: transparent;
  }

  @include media_custom_min_width(1900px) {
    background-position: center;
  }

  @include media_custom_min_width(2200px) {
    background-size: 1920px;
  }
}

.aboutTitle {
  font-size: 29px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 25px;
  margin-top: -8px;

  @include media_ss {
    font-size: 36px;
  }

  @include media_sm {
    font-size: 38px;
  }

  @include media_md {
    font-size: 40px;
  }

  @include media_lg {
    font-size: 52px;
  }
}

.aboutDescription {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 12px;
}

.aboutBtn {
  margin-top: 50px;
}
