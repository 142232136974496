*,
::after,
::before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #00394f;
  background-color: #fff;
  overflow-x: hidden;
  position: relative;
  margin: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  // for moz browser
  scrollbar-width: thin;
  scrollbar-color: #888 #e5e0e0;
}

#root {
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #e5e0e0;
}

::-webkit-scrollbar-thumb {
  background-color: #9c9c9c;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

h1,
h2 {
  font-family: 'Asimov Print', Arial, sans-serif;
  letter-spacing: 1.3px;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  line-height: 1.2;
}

p {
  font-size: 16px;
  line-height: 26px;
  margin: 0;
}

a {
  transition: 0.3s;
  text-decoration: none;
  color: inherit;
  vertical-align: top;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

i {
  display: inline-block;
}

input,
textarea,
button {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

textarea {
  resize: vertical;
}

button {
  transition: 0.3s;
  cursor: pointer;
}

img {
  max-width: 100%;
}

img,
svg {
  vertical-align: middle;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

input::placeholder {
  transition: 0.4s;
  opacity: 1;
  color: #53545b;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #00394f;
  -webkit-box-shadow: 0 0 0 0 transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

::-moz-selection {
  color: #fff;
  background: #23cc88;
}

::selection {
  color: #fff;
  background: #23cc88;
}

input:focus::placeholder {
  opacity: 0;
}

textarea::placeholder {
  transition: 0.4s;
  opacity: 1;
  color: #53545b;
}

textarea:focus::placeholder {
  opacity: 0;
}

iframe {
  border: 0;
}

// TODO: classes below were used in the "themeforest" template. Components which use them need to be refactored.
.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.g-0 {
  --bs-gutter-x: 0;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

@media (min-width: 576px) {
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
}

@media (min-width: 1200px) {
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
}

@media (min-width: 1400px) {
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.align-items-start {
  align-items: flex-start !important;
}
