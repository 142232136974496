@import '../../../assets/styles/medias';

.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 110%;
  left: 0;
  right: 0;
  z-index: 3;
  padding: 16px 40px 24px;
  background: var(--white);

  @include media_lg {
    width: 310px;
    padding: 20px;
    padding-bottom: 33px;
    display: flex;
    position: absolute;
    top: 65px;
    left: 0;
    border-top: 6px solid #42cd6f;
    flex-direction: column;
  }
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 18px;
  color: var(--color-2);
}

.pages,
.listYear,
.list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 26px;
  justify-content: space-between;
}

.ageList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 26px;
}

.active {
  background: #80ce40;
}

.page,
.year {
  cursor: pointer;
  height: 41px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
  border: 1px solid var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--color-2);
  border-color: var(--color-2);
  overflow: hidden;

  &:hover {
    background: #80ce40;
  }
}

.year {
  font-size: 14px;
}

.page {
  margin-right: 11px;
  width: 47%;
  &:nth-child(2n) {
    margin-right: 0px;
  }
  &:nth-child(3) {
    margin-bottom: 0px;
  }
  &:nth-child(4) {
    margin-bottom: 0px;
  }
}

.year {
  margin-right: 8px;
  width: 31%;
  &:nth-child(3n) {
    margin-right: 0px;
  }
}
.wrapperBtn {
  display: flex;
  justify-content: flex-end;
}

.btn {
  max-width: 116px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--white);
  padding: 10px;
  box-shadow: none;
  background: var(--gradient-1);

  &::after {
    background: var(--gradient-2);
  }
}
