@import '../../../../assets/styles/medias';
@import '../../../../assets/styles/placeholders';

.popularProduct {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.popularProductImageContainer {
  width: 75px;
  height: 83px;
  flex-shrink: 0;
}

.popularProductImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.popularProductInfo {
  flex-grow: 1;
  @extend %flex-between;
  flex-direction: column;
  min-height: 83px;
  gap: 3px;
}

.popularProductTitleContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
}

.popularProductTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  color: var(--color-2);
  overflow: hidden;
  margin: 0;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: var(--color-1);
  }

  @include media_sm {
    max-width: 210px;
    font-size: 18px;
  }
}

.popularProductMaterialInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 11px;
  gap: 3px;
}

.popularProductMaterialContainer {
  gap: 4px;
  margin-right: 0;
}

.popularProductMaterialText {
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  color: var(--black);
}

.popularProductMaterialCircle {
  width: 11px;
  height: 11px;
}

.popularProductSubjectAndAgeInfo {
  @extend %flex-between;
  align-items: center;
  gap: 12px;
}

.popularProductSubjectTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--color-2);
  transition: none;
}

.popularProductAgeInfo {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: var(--black);
  flex-shrink: 0;
  transition: none;
}

.popularProductCurriculumList {
  list-style: url('../../../../assets/svg/list-circle-mark.svg');
  padding: 0 9px 0 20px;
}

.popularProductCurriculumItem {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  color: var(--black);

  strong {
    font-weight: 600;
  }
}
