@import '../../assets/styles/medias';

.comments {
  max-width: 1120px;
}

.commentsTitleContainer {
  padding: 16px 12px;
  border: 1px solid var(--color-border);

  @include media_md {
    padding: 0;
    border: 0;
    margin-bottom: 44px;
  }
}

.commentsTitle {
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  color: var(--color-2);

  @include media_lg {
    font-size: 24px;
    line-height: 28px;
  }
}

.commentFormSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
  max-width: 1110px;
  margin-bottom: 50px;
}

.commentFormSectionTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  color: var(--color-2);
  text-align: left;
  margin-bottom: 22px;
}

.commentFormSectionDescription {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  color: var(--black);
  text-align: left;
  margin-bottom: 12px;
}

.commentFormLabel {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 13px;

  @include media_lg {
    margin-bottom: 28px;
    gap: 11px;
  }
}

.commentFormTxt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  color: var(--color-2);
}

.commentFormAuthorImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 41px;
  background-color: var(--color-1);
  border-radius: 50%;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: var(--white);
}

.commentFormTextArea {
  border: 1px solid var(--color-border);
  min-height: 130px;
  padding: 10px;
}

.commentFormButtonsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.commentFormCancelBtn,
.commentFormSubmitBtn {
  padding: 9px 13px;
  min-width: 140px;

  @include media_lg {
    padding: 11px 20px;
    min-width: 190px;
  }
}
