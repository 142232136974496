#root,
#modal-root {
  --color-error: #ff0000;
  --color-background: #f7f7f7;
  --color-border: #e9e9e9;
  --color-teal-green: #0bc5b7;
  --color-tab: #d7d7d7;
  --color-1: #23cc88;
  --color-2: #00394f;
  --color-7: #ff4f58;
  --black: #717171;
  --white: #fff;
  --white-2: #edf5f8;
  --white-3: #e9e9e9;
  --light-gray: #d8d8d8;
  --link-color: #00a6ff;
  --describtion-color: #3d3c3c;
  --gradient-1: linear-gradient(145deg, #8ecf35, #23cc88);
  --gradient-2: linear-gradient(-145deg, #8ecf35, #23cc88);
  --gradient-3: linear-gradient(145deg, #ff4f58, #ffb400);
  --gradient-4: linear-gradient(-145deg, #ff4f58, #ffb400);
  --gradient-5: linear-gradient(145deg, #01cbad, #47a1f2);
  --gradient-6: linear-gradient(-145deg, #01cbad, #47a1f2);
}
